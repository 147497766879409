// import * as UserApi from '../api/user';

import { toastr } from 'react-redux-toastr';
import { addHours } from 'date-fns';
import { user as UserApi, handleCommonApiErrors } from '../api';

// action types
export const types = {
  // AUTO_LOGIN: 'AUTH/AUTH_AUTO_LOGIN',
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_CHECK_SUCCESS: 'AUTH/LOGIN_CHECK_SUCCESS',
  LOGIN_CHECK_ERROR: 'AUTH/LOGIN_CHECK_ERROR',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_ERROR: 'AUTH/LOGIN_ERROR',
  LOGOUT: 'AUTH/LOGOUT',
  REDIRECT_SAVE: 'AUTH/REDIRECT_SAVE',
  REDIRECT_APPLY: 'AUTH/REDIRECT_APPLY',
  FORGOT_PASSWORD_OTP_REQUEST: 'AUTH/FORGOT_PASSWORD_OTP_REQUEST',
  FORGOT_PASSWORD_OTP_REQUEST_ERROR: 'AUTH/FORGOT_PASSWORD_OTP_REQUEST_ERROR',
  FORGOT_PASSWORD_OTP_REQUEST_SUCCESS:
    'AUTH/FORGOT_PASSWORD_OTP_REQUEST_SUCCESS',
  VERIFY_FORGOT_PASSWORD_OTP: 'AUTH/VERIFY_FORGOT_PASSWORD_OTP',
  VERIFY_FORGOT_PASSWORD_OTP_SUCCESS: 'AUTH/VERIFY_FORGOT_PASSWORD_OTP_SUCCESS',
  VERIFY_FORGOT_PASSWORD_OTP_ERROR: 'AUTH/VERIFY_FORGOT_PASSWORD_OTP_ERROR',
  RESET_PASSWORD: 'AUTH/RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'AUTH/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'AUTH/RESET_PASSWORD_ERROR',
  RESEND_OTP: 'AUTH/RESEND_OTP',
  RESEND_OTP_SUCCESS: 'AUTH/RESEND_OTP_SUCCESS',
  RESEND_PASSWORD_ERROR: 'AUTH/RESEND_PASSWORD_ERROR',

  FETCH_PROFILE_REQUEST: 'AUTH/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS: 'AUTH/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_ERROR: 'AUTH/FETCH_PROFILE_ERROR',

  FETCH_PHONE_PROFILE_REQUEST: 'AUTH/FETCH_PHONE_PROFILE_REQUEST',
  FETCH_PHONE_PROFILE_SUCCESS: 'AUTH/FETCH_PHONE_PROFILE_SUCCESS',
  FETCH_PHONE_PROFILE_ERROR: 'AUTH/FETCH_PHONE_PROFILE_ERROR',

  CLEAR_PHONE_PROFILE: 'AUTH/CLEAR_PHONE_PROFILE',

  UNBLOCK_OTP_REQUEST: 'AUTH/UNBLOCK_OTP_REQUEST',
  UNBLOCK_OTP_REQUEST_ERROR: 'AUTH/UNBLOCK_OTP_REQUEST_ERROR',
  UNBLOCK_OTP_REQUEST_SUCCESS: 'AUTH/UNBLOCK_OTP_REQUEST_SUCCESS',

  VERIFY_UNBLOCK_OTP: 'AUTH/VERIFY_UNBLOCK_OTP',
  VERIFY_UNBLOCK_OTP_SUCCESS: 'AUTH/VERIFY_UNBLOCK_OTP_SUCCESS',
  VERIFY_UNBLOCK_OTP_ERROR: 'AUTH/VERIFY_UNBLOCK_OTP_ERROR',

  FETCH_TEST_BOOKING_REQUEST: 'AUTH/FETCH_TEST_BOOKING_REQUEST',
  FETCH_TEST_BOOKING_SUCCESS: 'AUTH/FETCH_TEST_BOOKING_SUCCESS',
  FETCH_TEST_BOOKING_ERROR: 'AUTH/FETCH_TEST_BOOKING_ERROR',

  RETRY_REQUEST: 'AUTH/RETRY_REQUEST',
  RETRY_SUCCESS: 'AUTH/RETRY_SUCCESS',
  RETRY_ERROR: 'AUTH/FETCH_RETRY_ERROR',

  USERTYPE_REQUEST: 'AUTH/USERTYPE_REQUEST',
  USERTYPE_CHECK_SUCCESS: 'AUTH/USERTYPE_CHECK_SUCCESS',
  USERTYPE_CHECK_ERROR: 'AUTH/USERTYPE_CHECK_ERROR',
  USERTYPE_SUCCESS: 'AUTH/USERTYPE_SUCCESS',
  USERTYPE_ERROR: 'AUTH/USERTYPE_ERROR',
  USERTYPE_CHANGE_REQUEST: 'AUTH/USERTYPE_CHANGE_REQUEST',
  USERTYPE_CHANGE_ERROR: 'AUTH/USERTYPE_CHANGE_ERROR',
  USERTYPE_CHANGE_SUCCESS: 'AUTH/USERTYPE_CHANGE_SUCCESS',
};

// initial state
const initialState = {
  isLoading: false,
  verify: null,
  token: null,
  user: null,
  lumpSumCourse: null,
  redirectTo: '/',
  otpVerified: false,
  disableSendOTP: false,
  disableVerifyOTP: false,
  disableResetPassword: false,
  tokenExpiry: null,
};

// reducer
// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return { ...state, isLoading: true, tokenExpiry: null };

    case types.LOGIN_CHECK_SUCCESS:
      return { ...state, isLoading: false, verify: action.data };

    case types.LOGIN_CHECK_ERROR:
      return { ...state, isLoading: false };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verify: null,
        user: action.data.user,
        token: action.data.token,
        apiPermissions: action.data.apiPermissions,
        tokenExpiry: addHours(new Date(), 20),
        selectedUserType: action.data.user.selectedUserType,
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        verify: null,
        user: null,
        selectedUserType: null,
      };

    // case types.LOGOUT:
    //   return { ...state, user: null, token: null };

    case types.REDIRECT_SAVE:
      return { ...state, redirectTo: action.data };

    case types.REDIRECT_APPLY:
      return { ...state, redirectTo: '/' };

    case types.FORGOT_PASSWORD_OTP_REQUEST:
      return { ...state, disableSendOTP: true, otpVerified: false };

    case types.FORGOT_PASSWORD_OTP_REQUEST_ERROR:
      return {
        ...state,
        disableSendOTP: false,
        verify: null,
        otpVerified: false,
      };

    case types.FORGOT_PASSWORD_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        disableSendOTP: false,
        verify: action.data,
        otpVerified: false,
      };

    case types.UNBLOCK_OTP_REQUEST:
      return { ...state, disableSendOTP: true, otpVerified: false };

    case types.UNBLOCK_OTP_REQUEST_ERROR:
      return {
        ...state,
        disableSendOTP: false,
        verify: null,
        otpVerified: false,
      };

    case types.UNBLOCK_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        disableSendOTP: false,
        verify: action.data,
        otpVerified: false,
        message: action.message,
      };

    case types.VERIFY_FORGOT_PASSWORD_OTP:
      return { ...state, disableVerifyOTP: true, otpVerified: false };

    case types.VERIFY_FORGOT_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        disableVerifyOTP: false,
        verify: action.data,
        otpVerified: true,
      };
    case types.VERIFY_FORGOT_PASSWORD_OTP_ERROR:
      return { ...state, disableVerifyOTP: false, otpVerified: false };
    case types.RESET_PASSWORD:
      return { ...state, disableResetPassword: true };
    case types.RESET_PASSWORD_SUCCESS:
      return { ...state, disableResetPassword: false };
    case types.RESET_PASSWORD_ERROR:
      return { ...state, disableResetPassword: false };
    case types.RESEND_OTP:
      return { ...state, otpVerified: false };
    case types.RESEND_OTP_SUCCESS:
      return { ...state, otpVerified: false, verify: action.data };
    case types.RESEND_PASSWORD_ERROR:
      return { ...state, otpVerified: false };

    // profile
    case types.FETCH_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        student: action.data,
        userProfileLoaded: true,
      };
    case types.FETCH_PROFILE_ERROR:
      return { ...state, isLoading: false };

    // profile
    case types.FETCH_PHONE_PROFILE_REQUEST:
      // eslint-disable-next-line no-case-declarations
      const returnData = {
        ...state,
        isLoading: true,
        isNewRequest: action.isNewRequest,
        studentProfileLoaded: false,
      };
      if (action.isNewRequest) {
        returnData.studentDetails = null;
      }
      return returnData;
    case types.FETCH_PHONE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isNewRequest: false,
        studentDetails: action.data,
        lumpSumCourse: action.lumpSumCourse,
        studentProfileLoaded: true,
        route: action.request ? action.request.route : '',
        csrRole: action.request ? action.request.csrRole : 'csr',
      };
    case types.FETCH_PHONE_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        isNewRequest: false,
        studentProfileLoaded: true,
        studentDetails: null,
      };

    case types.CLEAR_PHONE_PROFILE:
      return {
        ...state,
        isLoading: false,
        studentDetails: null,
        studentProfileLoaded: null,
      };

    case types.VERIFY_UNBLOCK_OTP:
      return { ...state, disableVerifyOTP: true, unblockOtpVerified: false };

    case types.VERIFY_UNBLOCK_OTP_SUCCESS:
      return {
        ...state,
        disableVerifyOTP: false,
        verify: action.data,
        unblockOtpVerified: true,
      };
    case types.VERIFY_UNBLOCK_OTP_ERROR:
      return { ...state, disableVerifyOTP: false, unblockOtpVerified: false };
    // profile
    case types.FETCH_TEST_BOOKING_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        userRecord: null,
      };
    case types.FETCH_TEST_BOOKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        userRecord: action.data.userRecord,
        testBookingDetails: action.data.testBookingDetails,
      };
    case types.FETCH_TEST_BOOKING_ERROR:
      return {
        ...state,
        isLoading: false,
        isNewRequest: false,
        isLoaded: true,
        userRecord: null,
      };
    case types.RETRY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.RETRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    case types.RETRY_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    case types.USERTYPE_REQUEST:
      return { ...state, isLoading: true, userTypeData: null };

    case types.USERTYPE_CHECK_SUCCESS:
      return { ...state, isLoading: false, userTypeData: action.data };

    case types.USERTYPE_CHECK_ERROR:
      return { ...state, isLoading: false };

    case types.USERTYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userTypeData: action.data,
      };

    case types.USERTYPE_ERROR:
      return { ...state, isLoading: false, userTypeData: null };

    case types.USERTYPE_CHANGE_REQUEST:
      return { ...state, isLoading: true, userTypeData: null };

    case types.USERTYPE_CHANGE_ERROR:
      return { ...state, isLoading: false };

    case types.USERTYPE_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedUserType: action.data,
      };

    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  loginCheck: (credentials) => async (dispatch) => {
    dispatch({ type: types.LOGIN_REQUEST });
    // console.log('credentials: ', credentials);
    try {
      const response = await UserApi.checkUserLogin(credentials);
      // console.log('response(success): ', response);
      dispatch({
        // type: types.LOGIN_CHECK_SUCCESS,
        type: types.LOGIN_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        // type: types.LOGIN_CHECK_ERROR
        type: types.LOGIN_ERROR,
      });

      toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },

  login: (credentials) => async (dispatch) => {
    dispatch({ type: types.LOGIN_REQUEST });
    try {
      const response = await UserApi.loginUser(credentials);
      // console.log('response: ', response);
      dispatch({ type: types.LOGIN_SUCCESS, data: response.data });
      toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      dispatch({ type: types.LOGIN_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },

  logout: () => async (dispatch) => {
    dispatch({ type: types.LOGOUT });

    try {
      sessionStorage.clear();
    } catch (error) {
      toastr.error('Error', 'Error while logging out!');
      // toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },

  redirectSave: (to) => (dispatch) =>
    dispatch({ type: types.REDIRECT_SAVE, data: to }),
  redirectApply: () => (dispatch) => dispatch({ type: types.REDIRECT_APPLY }),

  forgotPassword: (credentials) => async (dispatch) => {
    dispatch({ type: types.FORGOT_PASSWORD_OTP_REQUEST });
    // console.log('credentials: ', credentials);
    try {
      const response = await UserApi.forgotPassword(credentials);
      // console.log('response(success): ', response);
      dispatch({
        type: types.FORGOT_PASSWORD_OTP_REQUEST_SUCCESS,
        data: response.data.data,
      });
    } catch (error) {
      dispatch({ type: types.FORGOT_PASSWORD_OTP_REQUEST_ERROR });

      toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },

  verifyForgotPasswordOTP: (credentials) => async (dispatch) => {
    // console.log('verifyForgotPasswordOTP credentials: ', credentials);
    dispatch({ type: types.VERIFY_FORGOT_PASSWORD_OTP });

    try {
      const response = await UserApi.verifyForgotPasswordOTP(credentials);
      dispatch({
        type: types.VERIFY_FORGOT_PASSWORD_OTP_SUCCESS,
        data: response.data.data,
      });
      toastr.success('OTP Verified', 'Please Reset password');
    } catch (error) {
      dispatch({ type: types.VERIFY_FORGOT_PASSWORD_OTP_ERROR });

      toastr.error('Error', 'Error Verifying OTP');

      // throw error;
      handleCommonApiErrors(error);
    }
  },

  resetPassword: (credentials) => async (dispatch) => {
    dispatch({ type: types.RESET_PASSWORD });
    // console.log('credentials: ', credentials);
    try {
      const response = await UserApi.resetPassword(credentials);
      // console.log('response(success): ', response);
      dispatch({
        type: types.RESET_PASSWORD_SUCCESS,
        data: response.data.data,
      });
      toastr.success('Success', 'Password changed successfully');
    } catch (error) {
      dispatch({ type: types.RESET_PASSWORD_ERROR });

      toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },

  resendOTP: (id) => async (dispatch) => {
    dispatch({ type: types.RESEND_OTP });
    try {
      const response = await UserApi.resendOTP(id);
      dispatch({ type: types.RESEND_OTP_SUCCESS, data: response.data.data });
      toastr.success('Success', response.data.message);
    } catch (error) {
      dispatch({ type: types.RESEND_PASSWORD_ERROR });
      toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },

  resendVerifyOTP: (id) => async (dispatch) => {
    dispatch({ type: types.RESEND_OTP });
    try {
      const response = await UserApi.resendOTP(id);
      dispatch({ type: types.RESEND_OTP_SUCCESS, data: response.data.data });
      // toastr.success('Success', response.data.message);
    } catch (error) {
      dispatch({ type: types.RESEND_PASSWORD_ERROR });
      // toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },

  fetchProfile: (request) => async (dispatch) => {
    dispatch({ type: types.FETCH_PROFILE_REQUEST });
    try {
      const response = await UserApi.getStudentProfile(request);
      const { data } = response;
      dispatch({ type: types.FETCH_PROFILE_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.FETCH_PROFILE_ERROR });
      handleCommonApiErrors(error);
    }
  },

  fetchProfileByPhone: (request, isNewRequest) => async (dispatch) => {
    dispatch({ type: types.FETCH_PHONE_PROFILE_REQUEST, isNewRequest });
    try {
      const response = await UserApi.fetchStudentDetailsByPhone(request);
      const { data } = response;
      if (
        data &&
        data.StudentCourse &&
        data.StudentCourse.courseType &&
        !data.StudentCourse.courseType.isLumpSumCourse
      ) {
        const courseResponse = await UserApi.getLumpSumCourse({
          licenseType: data.StudentCourse.licenseType._id,
        });
        const { data: courseData } = courseResponse;
        dispatch({
          type: types.FETCH_PHONE_PROFILE_SUCCESS,
          data,
          request,
          lumpSumCourse: courseData,
        });
      } else {
        dispatch({
          type: types.FETCH_PHONE_PROFILE_SUCCESS,
          data,
          request,
          lumpSumCourse: null,
        });
      }
    } catch (error) {
      dispatch({ type: types.FETCH_PHONE_PROFILE_ERROR });
      // throw error;
      handleCommonApiErrors(error);
    }
  },

  clearProfileByPhone: () => async (dispatch) => {
    dispatch({ type: types.CLEAR_PHONE_PROFILE });
  },
  unblockRequest: (username) => async (dispatch) => {
    dispatch({ type: types.UNBLOCK_OTP_REQUEST });
    // console.log('credentials: ', credentials);
    try {
      const response = await UserApi.unblockRequest(username);
      // console.log('response(success): ', response);
      dispatch({
        type: types.UNBLOCK_OTP_REQUEST_SUCCESS,
        data: response.data.data,
        message: response.data.message,
      });
    } catch (error) {
      dispatch({
        type: types.UNBLOCK_OTP_REQUEST_ERROR,
      });
      toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },
  unblockAdminOTP: (credentials) => async (dispatch) => {
    // console.log('verifyForgotPasswordOTP credentials: ', credentials);
    dispatch({ type: types.VERIFY_UNBLOCK_OTP });

    try {
      const response = await UserApi.unblockAdminOTP(credentials);
      dispatch({
        type: types.VERIFY_UNBLOCK_OTP_SUCCESS,
        data: response.data.data,
      });
      toastr.success('OTP Verified', 'Your account has been unbloked');
    } catch (error) {
      dispatch({ type: types.VERIFY_UNBLOCK_OTP_ERROR });

      toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },
  fetchRTATestBookingDetails: (request, isNewRequest) => async (dispatch) => {
    dispatch({ type: types.FETCH_TEST_BOOKING_REQUEST, isNewRequest });
    try {
      const response = await UserApi.fetchRTATestBookingDetails(request);
      const { data } = response;
      dispatch({ type: types.FETCH_TEST_BOOKING_SUCCESS, data, request });
    } catch (error) {
      dispatch({ type: types.FETCH_TEST_BOOKING_ERROR });
      // throw error;
      handleCommonApiErrors(error);
    }
  },
  getUserTypes: (credentials) => async (dispatch) => {
    dispatch({ type: types.USERTYPE_REQUEST });
    try {
      const response = await UserApi.checkUserType(credentials);
      dispatch({
        type: types.USERTYPE_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toastr.error('Error!', error.response.data.message);
      } else {
        toastr.error('Error!', 'Please try again!');
      }
      dispatch({
        type: types.USERTYPE_ERROR_ERROR,
      });
    }
  },

  changeUserType: (userType, userId) => async (dispatch) => {
    dispatch({ type: types.USERTYPE_CHANGE_REQUEST });
    try {
      const response = await UserApi.changeUserType(userType, userId);
      dispatch({
        type: types.USERTYPE_CHANGE_SUCCESS,
        data: response.data.selectedUserType,
      });
      const toastrMessage = `User type changed to ${userType} successfully! `;
      toastr.success('Success', toastrMessage);
    } catch (error) {
      dispatch({
        type: types.USERTYPE_CHANGE_ERROR,
      });
    }
  },
};
